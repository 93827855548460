<template>
<div class="OrderServices">
  <a-spin :spinning="spinning">
<!--  服务详情-->
<!--  <div v-if="!isShow">-->
  <div>
    <div style="border-bottom: 1px solid #edeff2;">
      <div class="moTitle">订购服务</div>
    </div>
    <div class="content">
      <div class="nameTime" style="display: flex;justify-content: space-between">
        <div>当前版本：标准版  </div>
        <div>有效期：永久有效</div>
<!--        <div>有效期：2022-07-11 至 2024-09-15  </div>-->
      </div>
<!--      <div class="nameTime">-->
<!--        <div style="display: flex;width: 17%;">仓库容量<div class="title">5G/年</div></div>-->
<!--        <a-progress style="width: 70%;" :percent="50" :show-info="false" />-->
<!--        <div class="capacity">剩余容量3G</div>-->
<!--      </div>-->
<!--      <div class="nameTime">-->
<!--        <div style="display: flex;width: 17%;">短信容量<div class="title">200条/年</div></div>-->
<!--        <a-progress style="width: 70%" :percent="50" :show-info="false" />-->
<!--        <div class="capacity">剩余100条</div>-->
<!--      </div>-->
<!--      <div class="nameTime">-->
<!--        <div style="display: flex;width: 17%;">短信容量<div class="title">200条/年</div></div>-->
<!--        <a-progress style="width: 70%" :percent="50" :show-info="false" />-->
<!--        <div class="capacity">剩余100条</div>-->
<!--      </div>-->
      <div>
        <table class="tableDiv">
          <tr>
            <td class="td-left">
              <div class="biaotou">
              </div>
            </td>
            <td v-for="list in orderServicesData" :class="{backGC:list.name === '免费版',bgImg:list.name === '免费版',titleHd:list.name !== '免费版'}" class="td tdTitle">
<!--              <div class="bgImg"></div>-->
<!--              <div style="background-image: url('../../../assets/icon/now.png');"></div>-->
              {{ list.name }}
            </td>
          </tr>
<!--          <tr>-->
<!--            <td class="td-left">专享功能</td>-->
<!--            <td class="td" :class="{backGC:currentVersion === 'free'}">免费微网站搭建</td>-->
<!--            <td class="td">包含免费版所有功能，及：会议直播，邀请专家</td>-->
<!--            <td class="td">包含标准版所有功能，及优先审批</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="td-left">仓库容量</td>-->
<!--            <td class="td" :class="{backGC:currentVersion === 'free'}">5G/年</td>-->
<!--            <td class="td">50G/年</td>-->
<!--            <td class="td">100G/年</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="td-left">短信容量</td>-->
<!--            <td class="td" :class="{backGC:currentVersion === 'free'}">200条/年</td>-->
<!--            <td class="td">500条/年</td>-->
<!--            <td class="td">1500条/年</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="td-left">服务</td>-->
<!--            <td class="td" :class="{backGC:currentVersion === 'free'}">无</td>-->
<!--            <td class="td" colspan="2">专享客服，提供发票与合同</td>-->
<!--          </tr>-->
          <tr>
            <td class="td-left"></td>
            <td class="td" v-for="list in orderServicesData" :class="{backGC:list.name === '免费版'}">
              <div>
                <span class="tdTitle">￥{{list.price}}</span>/年
              </div>
              <a-button v-if="list.name !== '免费版'" style="margin-top: 5px" @click="orderBtn(list)">升级为此版本</a-button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="selectOrder">
      <div style="height: 50px"></div>
      <div class="bottomSty">
        <div class="btn">
          <span class="number">订单总金额 <span style="font-size: 25px;color: red">{{selectOrderList.price}}元</span></span>
          <div>
            <a-checkbox :checked="selectAgreement" @change="onChange">已阅读并同意</a-checkbox> <a @click="openLink">《医驰企业版服务条款》</a>
            <a-button style="margin-left: 10px" type="primary" @click="submitOrder">提交订单</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--&lt;!&ndash; 支付订单 &ndash;&gt;-->
<!--  <div v-else>-->
<!--    <div style="border-bottom: 1px solid #edeff2;">-->
<!--      <div class="moTitle">支付订单</div>-->
<!--    </div>-->
<!--    <div class="card" >-->
<!--      <div class="cardTop">-->
<!--        <span><span class="topTitle">订单号：</span>{{orderInfo.orderNumber}}</span>-->
<!--        <span class="cardTop-font"><span class="topTitle">下单时间：</span>{{orderInfo.orderTime}}</span>-->
<!--        <span><span class="topTitle">支付时间：</span>{{orderInfo.paymentTime ? orderInfo.paymentTime :'-'}}</span>-->
<!--      </div>-->
<!--      <a-table :columns="columns" :data-source="data" :pagination="false">-->
<!--        <span slot="effectiveTimeTitle">-->
<!--          <div>预计生效时间</div>-->
<!--          <div style="color: #9c9b9b;font-size: 10px">实际期限以支付为准</div>-->
<!--        </span>-->
<!--        <span slot="expirationTimeTitle">-->
<!--          <div>预计到期时间</div>-->
<!--          <div style="color: #9c9b9b;font-size: 10px">实际期限以支付为准</div>-->
<!--        </span>-->
<!--      <span slot="orderMoney" slot-scope="text">-->
<!--        ￥{{text}}-->
<!--      </span>-->
<!--      <span slot="orderStatus" slot-scope="text">-->
<!--&lt;!&ndash;        0待付款 1已取消 2未付款 3待确认 4已支付   &ndash;&gt;-->
<!--         <a-tag v-if="text === 0" color="red">待付款</a-tag>-->
<!--         <a-tag v-if="text === 1">已取消</a-tag>-->
<!--         <a-tag v-if="text === 2">未付款</a-tag>-->
<!--         <a-tag v-if="text === 3" color="blue">待确认</a-tag>-->
<!--         <a-tag v-if="text === 4" color="green">已支付</a-tag>-->
<!--      </span>-->
<!--      </a-table>-->
<!--      <div class="payCard">-->
<!--        <div>-->
<!--          <div>订单有效期14天，请及时支付。{{ days }}天后未支付，订单将自动关闭</div>-->
<!--          <div>注：订单支付成功后，可进入 管理-订单管理菜单中申请开具发票</div>-->
<!--        </div>-->
<!--        <div style="margin-top: 10px">-->
<!--          <a-popconfirm placement="top" @confirm="cancelOrder">-->
<!--            <template slot="title">-->
<!--              是否确认取消订单？-->
<!--            </template>-->
<!--            <a-button>取消订单</a-button>-->
<!--          </a-popconfirm>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="moTitle">支付方式</div>-->
<!--    <div style="width: 98%;margin: auto">-->
<!--      <a-alert message="线下支付到账有延迟，并需提交银行回执予财务审核。" type="warning" show-icon />-->
<!--      <a-alert type="info" style="margin-top: 20px">-->
<!--        <div slot="message" style="font-size: 20px;font-weight: bold">对公帐号</div>-->
<!--        <div slot="description">-->
<!--          <p style="margin-top: 10px">户 名: 广州市优屏科技有限公司</p>-->
<!--          <p>开户行: 中国民生银行华南支行</p>-->
<!--          <p>账 号: 695281930</p>-->
<!--        </div>-->
<!--      </a-alert>-->
<!--      <a-alert type="info" style="margin-top: 20px">-->
<!--        <div slot="message"></div>-->
<!--        <div slot="description">-->
<!--          <p>如果您已打款至以上帐号，请上传银行回执。</p>-->
<!--          <p>各大银行对公转账到账时间需要2小时～3个工作日不等。</p>-->
<!--          <p>我们的工作人员会在收到付款后第一时间为您开通服务，并通过短信通知您</p>-->
<!--          <div style="position: relative">-->
<!--            <a-button>上传银行回执</a-button>-->
<!--            <div class="upload">-->
<!--            <input-->
<!--                id="uploadImgId"-->
<!--                style="position: absolute;top: 0;left: 0;border: #333333 1px solid;width: 115px;height: 30px;opacity: 0"-->
<!--                type="file"-->
<!--                @change="uploadImg"-->
<!--                ref="uploadImg"/>-->
<!--          </div>-->

<!--          </div>-->
<!--          <p>如有疑问，请联系客服，电话: 18122188708， 邮箱: pansongan@yichimeeting.com</p>-->
<!--        </div>-->
<!--      </a-alert>-->
<!--      <a-alert type="error" style="margin-top: 20px">-->
<!--        <div slot="message" style="font-size: 20px;font-weight: bold">对公转账发票申请注意事项：</div>-->
<!--        <div slot="description">-->
<!--          <p style="margin-top: 10px">个人帐号转账至我司对公帐号只能开具个人普通发票。</p>-->
<!--          <p>公司帐号转账至我司对公帐号可开具企业增值税普通发票或企业增值税专用发票。</p>-->
<!--        </div>-->
<!--      </a-alert>-->
<!--    </div>-->
<!--  </div>-->
  </a-spin>
</div>
</template>

<script>

import {
  cancellationOrder,
  postOrder,
  subscriptionServices,
  unpaidOrder,
  uploadBankReceipt
} from "@/service/authentication";
import {update} from "@/utils/update";

const data = [];
const columns = [
  {
    title: '订单类型',
    dataIndex: 'orderType',
    key: 'orderType',
    scopedSlots: { customRender: 'orderType' },
  },
  {
    title: '下单金额',
    dataIndex: 'orderMoney',
    key: 'orderMoney',
    scopedSlots: { customRender: 'orderMoney' },
  },
  {
    title: '订购服务',
    dataIndex: 'serviceName',
    key: 'serviceName',
    scopedSlots: { customRender: 'serviceName' },
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    scopedSlots: { customRender: 'orderStatus' },
  },
  {
    dataIndex: 'effectiveTime',
    key: 'effectiveTime',
    slots: { title: 'effectiveTimeTitle' },
  },
  {
    slots: { title: 'expirationTimeTitle' },
    dataIndex: 'expirationTime',
    key: 'expirationTime',
  },
];
export default {
  name: "OrderServices",
  data(){
    return{
      spinning:false,
      days:0,
      isShow:0,
      data,
      columns,
      currentVersion:'free', //free 免费
      orderServicesData:[],
      selectOrder:false,
      selectOrderList:[],
      selectAgreement:false,
      orderInfo:{},
      // bankReceipt:'',
    }
  },
  mounted() {
    // this.startCountdown();
    // setInterval(this.startCountdown, 1000);
  },
  created() {
    this.getSubscriptionServices()
  },
  methods:{
    //获取订购服务列表
    async getSubscriptionServices() {
      this.spinning = true;
      const response = await subscriptionServices();
      if (response.code === 0) {
        // this.isShow = response.isShow;
        // if(response.isShow === 0){ //没有订单
          this.orderServicesData =  response.data;
        // }else {
        //   //有订单未支付
        //   await this.getUnpaidOrder();
        // }
        //倒计时
        this.startCountdown();
        setInterval(this.startCountdown, 1000);
      } else {
        this.$message.warning(response.message);
      }
      this.spinning = false;
    },

    async getUnpaidOrder() {
      const response = await unpaidOrder();
      if (response.code === 0) {
        this.data = [response.data];
        this.orderInfo = response.data;
      }else {
        this.$message.warning(response.message);
      }
    },
    //倒计时
    startCountdown() {
      const now = new Date().getTime();
      let payDate = this.orderInfo.orderTime;
      // 获取当前时间
      const currentDate = new Date(payDate);
      // 添加14天
      currentDate.setDate(currentDate.getDate() + 14);
      // 获取14天后的时间
      const futureDate = currentDate;

      const distance = futureDate.getTime() - now;

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    },
    async uploadImg() {
      let inputDOM = this.$refs.uploadImg.files[0];
      //判断图片大小
      // if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          // this.bankReceipt = image.data.url;
          let data = {
            "id":this.orderInfo.id,//订单id
            "bankReceipt": image.data.url//银行回执单图片
          }
          await this.upBankReceipt(data)
        } else {
          this.$message.warning("上传失败");
        }
      // } else {
      //   this.$message.warning("请上传小于1M的图片！");
      // }
      document.getElementById('uploadImgId').value = null;
    },
    //上传银行回执
    async upBankReceipt(data) {
      const response = await uploadBankReceipt(data);
      if (response.code === 200) {
        //刷新
        await this.getSubscriptionServices();
        this.$message.success("上传成功");
      }else {
        this.$message.warning(response.message);
      }
    },
    cancelOrder(){ //取消订单
      let data = {
        "id":this.orderInfo.id
      }
      this.putAncellationOrder(data);
    },
    async putAncellationOrder(data) {
      const response = await cancellationOrder(data);
      if (response.code === 200) {
        this.$message.success("订单已取消！")
        await this.getSubscriptionServices();
      }else {
        this.$message.warning(response.message);
      }
    },
    orderBtn(row){
      this.selectOrder = true;
      this.selectOrderList = row;
    },
    //获取账号未付款的订单信息
    submitOrder(){
      if(this.selectAgreement){ //已勾选
        let data = {
          "orderType": "订购服务",
          "orderMoney": this.selectOrderList.price,
          "serviceCode": this.selectOrderList.code,
          "serviceName": this.selectOrderList.name
        }
        this.order(data);
      }else {
        this.$message.warning("请阅读并勾选医驰企业版服务条款！")
      }
    },
    openLink() {
      window.open("../userAg.html");
    },
    async order(data) {
      const response = await postOrder(data);
      if (response.code === 200) {
        this.$message.success('提交成功！');
        await this.getSubscriptionServices();
        this.selectAgreement = false;
        this.selectOrder = false;
        // this.isShow = true;
      }else {
        this.$message.warning(response.message);
      }
    },
    onChange(e) {
      this.selectAgreement = e.target.checked;
    },
  }
}
</script>

<style scoped lang="scss">
.OrderServices{
  border: 10px solid #edeff2;
  .moTitle {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: auto;
    height: 50px;
    line-height: 50px;
    font-weight: 600;
    color: $topTitleColor;
  }
  .content{
    margin: 30px;
    .nameTime{
      display: flex;
      margin-bottom: 20px;
      color: #333333;
      font-size: 16px;
      .title{
        margin-left: 20px;
        width: 100px;
      }
      .capacity{
        width: 13%;
        text-align: right;
        //margin-left:30px;
      }
    }
  }
  .tableDiv{
    width: 100%;
    .bgImg{
      background-image: url('../../../assets/icon/now.png');
      background-repeat: no-repeat;
      background-size: 40.5px 34.5px;
    }
    .backGC{
      background-color: rgb(205,230,240);
    }
    .td-left{
      width:200px;
      //height:50px;
      background-color: #fff;
      text-align: center;
      border: 1px solid #e5e4e4;
    }.td{
      width: 30%;
      height: 80px;
      text-align: center;
      border: 1px solid #e5e4e4;
    }
    .tdTitle{
      font-size: 18px;
      font-weight: bold;
    }
    .titleHd{
      background-color: #e0dede;
    }
    .biaotou {
      width:200px;height:100px;
      background:
          linear-gradient(to top right,
              transparent 0%,
              transparent calc(50% - 1px),
              #d9d7d7 50%,
              transparent calc(50% + 1px),
              transparent 100%
          )

    }

  }
  .bottomSty {
    width: 100%;
    height: 50px;
    position: fixed;
    bottom: 0;
    z-index: 9;
    background-color: #f0f2f5;
    border-bottom: 1px solid #fff;
    .btn {
      width: 83%;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
  .number{
    font-size: 16px;
    margin-left: 25px;
  }
}
.card{
  width: 98%;
  padding: 20px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 300px;
  border: 1px solid #edeff2;
  box-shadow: 1px 1px 15px #d7d7d7;
  .cardTop{
    .topTitle{
      font-weight: 600;
      color: $topTitleColor;
    }
    margin-bottom: 20px;
    margin-top: 10px;
    .cardTop-font{
      margin-left: 50px;
      margin-right: 50px;
    }
  }
  .payCard{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}
</style>
